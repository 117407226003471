import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "dynamic-data-in-the-browser"
    }}>{`Dynamic data in the browser`}</h1>
    <p>{`In the browser, your JAMStack app becomes a typical React app and loading data is a common operation.`}</p>
    <p>{`You're going to learn the `}<strong parentName="p">{`declarative query`}</strong>{` approach to data loading. Both for GraphQL and REST.`}</p>
    <p>{`In the interest of time, we're doing these exercises with Gatsby. You can use the same approach with NextJS.`}</p>
    <h2 {...{
      "id": "graphql-with-apollo-client"
    }}>{`GraphQL with Apollo Client`}</h2>
    <p>{`GraphQL creates a flexible interface to your data. It moves flexibility (and complexity) to the frontend so you can iterate faster.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/react/"
      }}>{`Apollo Client`}</a>{` is the most popular GraphQL client and the library that popularized declarative queries. Comes with built-in query caching and request optimizations.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Continue with your Gatsby project from before, or jump into mine in `}<inlineCode parentName="p">{`exercise-4`}</inlineCode>{`. Use the `}<a parentName="p" {...{
        "href": "https://api.spacex.land/graphql/"
      }}>{`SpaceX GraphQL API`}</a>{` create a page that lists SpaceX's last 10 launches.`}</p>
    <p>{`As always with Gatsby, `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/plugins/gatsby-plugin-apollo/"
      }}>{`there's a plugin for this`}</a>{`.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`gatsby-plugin-apollo`}</inlineCode>{` and `}<inlineCode parentName="p">{`@apollo/client`}</inlineCode>{` and enable the plugin in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// gatsby-config.js

{
    resolve: 'gatsby-plugin-apollo',
    options: {
    uri: 'https://api.spacex.land/graphql'
    }
}
`}</code></pre>
    <p>{`You can now query the SpaceX API in any React component with the `}<inlineCode parentName="p">{`useQuery`}</inlineCode>{` hook. I suggest making a self-contained `}<inlineCode parentName="p">{`<LaunchList>`}</inlineCode>{` component. Something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { gql, useQuery } from "@apollo/client"

export const LaunchList = () => {
  const { loading, error, data } = useQuery(gql\`
    {
      launchesPast(limit: 10) {
        mission_name
        links {
          article_link
          video_link
        }
        rocket {
          rocket_name
        }
      }
    }
  \`)

  if (loading) {
    // indicate loading state
  } else if (error) {
    // show an error
  } else {
    // list launches, they're in data.launchesPast
  }
}
`}</code></pre>
    <p>{`PS: for NextJS, you'll have to wrap `}<inlineCode parentName="p">{`_app.js`}</inlineCode>{` in `}<inlineCode parentName="p">{`<ApolloProvider>`}</inlineCode>{` yourself becuse there's no plugin. The rest stays the same.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-4-solution`}</inlineCode>{` branch.`}</p>
    <h2 {...{
      "id": "rest-with-react-query"
    }}>{`REST with React Query`}</h2>
    <p><a parentName="p" {...{
        "href": "https://react-query.tanstack.com/"
      }}>{`React Query`}</a>{` took the idea of declarative queries from GraphQL and applied it to REST. Comes with many of the same benefits like caching and optimization.`}</p>
    <p>{`Unlike GraphQL, React Query can't understand your queries, which means it can't optimize at the request fragment level.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Use the `}<a parentName="p" {...{
        "href": "https://api.spacex.land/rest/"
      }}>{`SpaceX REST API`}</a>{` to create a page that lists SpaceX's last 10 launches.`}</p>
    <p>{`There's no Gatsby plugin for React Query. You'll have to set it up yourself.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`react-query`}</inlineCode>{` and wrap your component root in the cache provider. Add it to both `}<inlineCode parentName="p">{`gatsby-browser.js`}</inlineCode>{` and `}<inlineCode parentName="p">{`gatsby-ssr.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { QueryCache, ReactQueryCacheProvider } from "react-query"

const queryCache = new QueryCache()

export const wrapRootElement = ({ element }) => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      {element}
    </ReactQueryCacheProvider>
  )
}
`}</code></pre>
    <p>{`You can now query REST APIs from anywhere with the `}<inlineCode parentName="p">{`useQuery`}</inlineCode>{` hook. I recommend creating a new `}<inlineCode parentName="p">{`LaunchListREST`}</inlineCode>{` component. Like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { useQuery } from "react-query"

export const LaunchListREST = () => {
  const { isLoading, error, data } = useQuery(["launches-past"], async () => {
    const res = await fetch(
      "https://api.spacex.land/rest/launches-past?limit=10"
    )
    return res.json()
  })

  if (loading) {
    // indicate loading state
  } else if (error) {
    // show an error
  } else {
    // list launches, they're in data
  }
}
`}</code></pre>
    <p>{`Look at your network log, see the difference in how much data you load with GraphQL vs. REST.`}</p>
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-4-solution`}</inlineCode>{` branch.`}</p>
    <h2 {...{
      "id": "wormhole-state-management"
    }}>{`Wormhole state management`}</h2>
    <p>{`The core benefit of the declarative query approach to data loading is that performance is solved for you. Run the query wherever you need data, don't worry about repeat requests.`}</p>
    <h3 {...{
      "id": "exercise-2"
    }}>{`Exercise`}</h3>
    <p>{`Take your launches page and add a button that renders a new `}<inlineCode parentName="p">{`<LaunchList>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<LaunchListREST>`}</inlineCode>{` component on the same page. See what happens in your network tab.`}</p>
    <p>{`Now add a staleness timeout to your REST query and try again.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    staleTime: 5 * 60 * 1000,
}
`}</code></pre>
    <h3 {...{
      "id": "solution-2"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-4-solution`}</inlineCode>{` branch.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      